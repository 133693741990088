import { defineComponent,onMounted, reactive, ref } from 'vue'
import { transactionList, refundList, orderList, remaining } from '@/api/index'
import { useRouter } from 'vue-router'
import { SET_AMOUNT_INFO } from '@/utils/constant'
const { throttle } = require('../../../utils/tils')
import { useStore } from 'vuex'
import { Toast } from 'vant'
import './index.less'


const orderState = new Map([
    [0, '待付款'],
    [1000, '已支付'],
    [2000, '交易取消'],
    [10000, '申请退款'],
    [11000, '已退款'],
]) 

const refundState = new Map([
    [0, '退款中'],
    [100, '处理中'],
    [200, '已退款'],
    [1000, '退款失败'],
    [2000, '取消']
])

const typeStatus = new Map([
    [-1, '邀约面试服务费'],
    [0, '内推奖金(投递成功)'],
    [200, '内推奖金(推荐成功)'],
    [400, '内推奖金(面试通过)'],
    [500, '内推奖金(实际到岗)'],
    
])

export default defineComponent({
    setup () {
        const store = useStore()
        const router = useRouter()

        const state = reactive({
            jiaoyi: {
                data: [],
                pageNum: 0,
                pageSize: 10,
                totalRecord: 0,
                loading: false,
                finished: false,
            },
            chongzhi: {
                data: [],
                pageNum: 0,
                pageSize: 10,
                totalRecord: 0,
                loading: false,
                finished: false,
            },
            refund: {
                data: [],
                pageNum: 0,
                pageSize: 10,
                totalRecord: 0,
                loading: false,
                finished: false,
            },
            money: 0,
            activeName: "1"
        })

        onMounted(() => {
            _remaining()
            clearnRechargeMoney()
        })

        const clearnRechargeMoney = () => {
            console.log(store,'zhebianyou.....')
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
              });
            if (store.getters.rechargeMoney !== 0) {
                store.commit('setRechargeMoney', 0)
            }
            if (store.getters.amountKey) {
                state.activeName = store.getters.amountKey
            }
            let timers: any = setTimeout(() => {
                clearTimeout(timers)
                timers = null
                store.commit('setAmountKey', undefined)
            }, 4000)
        }

        const _remaining = async () => {
            const res:any = await remaining()
            state.money = res.data
        }

        const orders = async (limit = 10, start = 0) => {
            state.chongzhi.loading = true // 是否处于加载状态，加载过程中不触发load事件  默认false
            if (state.chongzhi.data.length !==0 && state.chongzhi.data.length >= state.chongzhi.totalRecord) {
                state.chongzhi.loading = false
                state.chongzhi.finished = false
                return 
            }
            const res: any = await orderList({limit, start})
            state.chongzhi.loading = false
            state.chongzhi.data = state.chongzhi.data.concat(res.data)
            state.chongzhi.pageNum = res.pageNum
            state.chongzhi.pageSize = res.pageSize
            state.chongzhi.totalRecord = res.totalRecord
            console.log(res, '充值明细。。。')
        }

        const _orderList = throttle(() => {
            const limit = state.chongzhi.pageNum === 0 ? 0 : (state.chongzhi.pageNum - 1) + 1
            orders(state.chongzhi.pageSize, limit * state.chongzhi.pageSize)
            //orders()
        }, 2000) 

        const _refud = async (limit = 10, start = 0) => {
            state.refund.loading = true // 是否处于加载状态，加载过程中不触发load事件  默认false
            if (state.refund.data.length !==0 && state.refund.data.length >= state.refund.totalRecord) {
                state.refund.loading = false
                state.refund.finished = false
                return 
            }
            const res: any = await refundList({limit, start})
            state.refund.loading = false
            console.log(res, '交易明细。。。')
            state.refund.data = state.refund.data.concat(res.data)
            state.refund.pageNum = res.pageNum
            state.refund.pageSize = res.pageSize
            state.refund.totalRecord = res.totalRecord
        }

        /**
         * 退款明细
         */
        const _refundList = throttle(() => {
            const limit = state.refund.pageNum === 0 ? 0 : (state.refund.pageNum - 1) + 1
            _refud(state.refund.pageSize, limit * state.refund.pageSize)
            //orders()
        }, 2000) 

        const _transaction = async (limit = 10, start = 0) => {
            state.jiaoyi.loading = true // 是否处于加载状态，加载过程中不触发load事件  默认false
            if (state.jiaoyi.data.length !==0 && state.jiaoyi.data.length >= state.jiaoyi.totalRecord) {
                state.jiaoyi.loading = false
                state.jiaoyi.finished = false
                return 
            }
            const res: any = await transactionList({limit, start}) 
            state.jiaoyi.loading = false
            state.jiaoyi.data = state.jiaoyi.data.concat(res.data);
            state.jiaoyi.pageNum = res.pageNum
            state.jiaoyi.pageSize = res.pageSize
            state.jiaoyi.totalRecord = res.totalRecord
            console.log(res, '交易记录...')
        }


        /**
         * 交易明细
         * @param limit 
         * @param start 
         */
        const _transactionList = throttle(() => {
            const limit = state.jiaoyi.pageNum === 0 ? 0 : (state.jiaoyi.pageNum - 1) + 1
            _transaction(state.jiaoyi.pageSize, limit * state.jiaoyi.pageSize)
        }, 2000) 

        const onChangeTab = (key: any) => {
            const test:any = {
                1: () => null,//_transactionList(),
                2: () => null,//_orderList(),
                3: () => null,//_refundList()
            }
            test[key]()
        }

        const onRecharge = () => {
            router.push({ path: '/recharge' })
            
        }

        const onRechargeInfo = (type: number, item: any) => {
            const key = type === 0 ? "1": type === 1 ? "2": "3"
            store.commit('setAmountKey', key)
            localStorage.setItem(SET_AMOUNT_INFO, JSON.stringify({ type, item }))
            router.push({path: '/rechargeInfo'})
        }

        const onRefund = () => {
            router.push({path: '/refund'})
        }

        const jiaoyiUI = () => {
            const { data } = state.jiaoyi 
            return data.map((item: any) => {
                return (
                    <div class='content-list' key={item.id} onClick={() => onRechargeInfo(0, item)}>
                        <div>
                            <div>
                                { typeStatus.get(item.status) }
                            </div>
                            <div class='time'>{item.createTime}</div>
                        </div>
                        <div class="right-icon">
                            <span class='red'>
                                {
                                    item.status === -1 ?  `-${Number(!item.money ? 0 : item.money).toFixed(2)}(限时折扣)` : `-${Number(!item.money ? 0 : item.money).toFixed(2)}`
                                }
                            </span>
                            <van-icon name="arrow" />
                        </div>
                    </div>
                )
            })
        }

        return () => (
            <div class="personal-page">
                <div class="header-box flex-box justify-content-between plr35">
                    <div>余额管理</div>
                    <img class="logo" src="https://img.fanwoon.com/new_logo.png" />
                </div>
                <div class='account-header-box'>
                    <div class='title'>可用余额</div>
                    <div class='account'>{Number(!state.money ? 0 : state.money).toFixed(2)}</div>
                    <div class='zijinbaozhang-box'>
                        <img src='https://img.fanwoon.com/anquanguanli%402x.png' />
                        <span>资金安全有保障</span>
                    </div>
                    <div class='account-btns-box'>
                        <button class='cancel-btn' onClick={() => onRefund() }>申请退款</button>
                        <button class='recharg-btn' onClick={() => onRecharge()}>充值</button>
                    </div>
                </div>
                <div>
                <van-tabs active={state.activeName} onChange={onChangeTab}>
                    <van-tab title="交易" name="1">
                        <van-list
                         v-model={state.jiaoyi.loading}
                         finished={state.jiaoyi.finished}
                         onLoad={_transactionList}
                        finished-text="没有更多了">
                            { jiaoyiUI() }
                        </van-list>
                    </van-tab>
                    <van-tab title="充值" name="2">
                        <van-list
                            v-model={state.chongzhi.loading}
                            finished={state.chongzhi.finished}
                            onLoad={_orderList}
                            finished-text="没有更多了">
                                {
                                    state.chongzhi.data.map((item: any) => {
                                        return (
                                            <div class='content-list' key={item.id} onClick={() => onRechargeInfo(1, item)}>
                                                <div>
                                                    <div>余额充值</div>
                                                    <div class='time'>{item.createTime}</div>
                                                </div>
                                                <div class="right-icon">
                                                    <div class={`mark-box ${item.orderState === 1000 ? 'green' :''} ${item.orderState === 2000 ? 'gray' : ''} ${item.orderState === 0 ? 'org': ''}`}>
                                                        <div class='price-box'>+{Number(!item.price ? 0 : item.price).toFixed(2)}</div>
                                                        <div>{orderState.get(item.orderState)}</div>
                                                    </div>
                                                    <van-icon name="arrow" />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </van-list>
                    </van-tab>
                    <van-tab title="退款" name="3">
                        <van-list
                             v-model={state.refund.loading}
                             finished={state.refund.finished}
                             onLoad={_refundList}
                                finished-text="没有更多了">
                                   {
                                       state.refund.data.map((item: any) => {
                                            return (
                                                <div class='content-list' key={item.id} onClick={() => onRechargeInfo(2, item)}>
                                                    <div>
                                                        <div>退款</div>
                                                        <div class='time'>{item.createTime}</div>
                                                    </div>
                                                    <div class="right-icon">
                                                        <div class="mark-box">
                                                            <div class="red">-{Number(!item.price ? 0 : item.price).toFixed(2)}</div>
                                                            <div class={`${item.refundStatus === 0 ? 'org' : ''} ${item.refundStatus === 1000 ? 'red' : ''} ${item.refundStatus === 200 ? 'green' : ''}`}>{refundState.get(item.refundStatus)}</div>
                                                        </div>
                                                        <van-icon name="arrow" />
                                                    </div>
                                                </div>
                                            )
                                       })
                                   }
                                </van-list>
                    </van-tab>
                </van-tabs>
                </div>
            </div>
        )
    }
})